@import "../scss/variables";

@charset "UTF-8";
@import url("../iconfonts/font-awesome/css/font-awesome.min.css");
@import url("../iconfonts/glyphicons/glyphicon.css");
@import url("../iconfonts/ionicons/ionicons.css");
@import url("../iconfonts/linearicons/Linearicons.css");
@import url("../iconfonts/materialdesignicons/materialdesignicons.css");
@import url("../iconfonts/pe-icon-7-stroke/Pe-icon-7.css");
@import url("../iconfonts/simple-line-icons/simple-line-icons.css");
@import url("../iconfonts/themify/themify.css");
@import url("../iconfonts/typicons/typicons.css");
@import url("../iconfonts/weathericons/weathericons.css");
@import url("../iconfonts/feather/feather.css");
@import url("../plugins/iconfonts/remixicon/remixicon.css");